<template>
    <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 519.1 154.7"
        style="enable-background: new 0 0 519.1 154.7"
        xml:space="preserve"
        sodipodi:docname="NorthPointLabs-Logo (1) (2).svg"
        inkscape:version="1.0beta2 (2b71d25, 2019-12-03)"
        :height="height"
        :width="width"
      >
        <metadata id="metadata120">
        </metadata>
        <defs id="defs118" />
        <path
          d="M 144.52927,76.382118 A 62.389267,22.645834 0 0 1 82.140007,99.027952 62.389267,22.645834 0 0 1 19.75074,76.382118 62.389267,22.645834 0 0 1 82.140007,53.736284 62.389267,22.645834 0 0 1 144.52927,76.382118"
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #405866;
            stroke-width: 4.76591;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="middleRing"
        />
        <path
          inkscape:connector-curvature="0"
          d="M 113.27314,130.44827 A 62.389267,22.645834 60.065186 0 1 62.515263,87.682715 62.389267,22.645834 60.065186 0 1 51.006857,22.31597 a 62.389267,22.645834 60.065186 0 1 50.757883,42.765551 62.389267,22.645834 60.065186 0 1 11.5084,65.366749"
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #405866;
            stroke-width: 4.76591;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="leftRing"
        />
        <path
          inkscape:connector-curvature="0"
          d="M 113.3171,22.341306 A 22.645834,62.389267 29.981407 0 1 101.75554,87.698667 22.645834,62.389267 29.981407 0 1 50.962907,130.42293 22.645834,62.389267 29.981407 0 1 62.524463,65.065562 22.645834,62.389267 29.981407 0 1 113.3171,22.341306"
          style="
            fill: none;
            fill-opacity: 1;
            stroke: #405866;
            stroke-width: 4.76591;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          id="rightRing"
        />

        <g id="electron1">
          <path
            style="
              fill: #ffffff;
              fill-opacity: 1;
              stroke: none;
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
            id="path79-0-3"
            d="m 102.34204,29.544819 c -1.91264,3.415422 -6.284362,4.644974 -9.699789,2.595721 -3.415422,-1.912637 -4.644974,-6.284377 -2.595721,-9.699799 1.912637,-3.415422 6.284378,-4.644974 9.699794,-2.595721 3.278806,1.912637 4.508356,6.284376 2.595716,9.699799"
            inkscape:connector-curvature="0"
          />
          <path
            inkscape:connector-curvature="0"
            style="
              fill: #ee8922;
              fill-opacity: 1;
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
            id="path126"
            d="m 91.649217,28.570559 c -1.4,-2.5 -0.6,-5.7 1.9,-7.1 2.5,-1.4 5.699997,-0.6 7.099993,1.9 1.4,2.5 0.6,5.7 -1.899993,7.1 -2.5,1.5 -5.7,0.6 -7.1,-1.9 z"
          />
        </g>
        <g id="electron3">
          <path
            inkscape:connector-curvature="0"
            d="m 38.214894,93.530927 c -1.912637,3.415422 -6.284376,4.644974 -9.699799,2.595721 -3.415422,-1.912637 -4.644974,-6.284377 -2.595721,-9.699799 1.912637,-3.415422 6.284377,-4.644974 9.699799,-2.595721 3.278805,1.912637 4.508357,6.284376 2.595721,9.699799"
            id="path79-0"
            style="
              fill: #ffffff;
              fill-opacity: 1;
              stroke: none;
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
          />
          <path
            inkscape:connector-curvature="0"
            style="
              fill: #ee8922;
              fill-opacity: 1;
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
            id="path79"
            d="m 36.555054,92.578888 c -1.4,2.5 -4.6,3.4 -7.1,1.9 -2.5,-1.4 -3.4,-4.6 -1.9,-7.1 1.4,-2.5 4.6,-3.4 7.1,-1.9 2.4,1.4 3.3,4.6 1.9,7.1"
          />
        </g>
        <g id="electron2">
          <path
            style="
              fill: #ffffff;
              fill-opacity: 1;
              stroke: none;
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
            id="path79-0-4"
            d="m 124.96539,118.47382 c -1.91263,3.41542 -6.28437,4.64497 -9.6998,2.59572 -3.41542,-1.91264 -4.64497,-6.28438 -2.59572,-9.6998 1.91264,-3.41542 6.28438,-4.64497 9.6998,-2.59572 3.27881,1.91264 4.50836,6.28438 2.59572,9.6998"
            inkscape:connector-curvature="0"
          />
          <path
            inkscape:connector-curvature="0"
            style="
              fill: #ee8922;
              fill-opacity: 1;
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
            id="path124"
            d="m 118.77407,109.72178 c 2.9,0 5.2,2.3 5.2,5.2 0,2.9 -2.3,5.2 -5.2,5.2 -2.9,0 -5.2,-2.3 -5.2,-5.2 -0.1,-2.9 2.3,-5.2 5.2,-5.2 z"
          />
        </g>
        <path
          inkscape:connector-curvature="0"
          d="m 94.210729,122.18869 c -3.780383,6.71254 -12.421249,9.12904 -19.171931,5.10153 -6.750683,-3.75905 -9.180926,-12.35108 -5.13052,-19.06362 3.780385,-6.71251 12.421254,-9.129054 19.171933,-5.10153 6.480653,3.75901 8.910898,12.35106 5.130518,19.06362"
          id="coverlet"
          style="
            fill: #ffffff;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 12.8337;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
          "
        />
        <path
          style="
            stroke-width: 10;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            fill: #405866;
            fill-opacity: 1;
          "
          d="m 88.900391,121.19922 c 3.2,0 3.2,4.90039 0,4.90039 h -3.300782 c -0.2,1.3 -1.7,2.30078 -3.5,2.30078 -1.8,0 -3.3,-1.00078 -3.5,-2.30078 h -3.40039 c -3.2,0 -3.2,-4.90039 0,-4.90039 z"
          id="bulbBaseLower"
        />
        <path
          style="
            stroke-width: 10;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke: none;
            stroke-opacity: 1;
            fill: #405866;
            fill-opacity: 1;
          "
          d="m 91.099609,114.69922 c 3.2,0 3.2,4.90039 0,4.90039 h -18 c -3.2,-0.1 -3.2,-4.90039 0,-4.90039 z"
          id="bulbBaseUpper"
        />
        <path
          style="
            fill: #ffffff;
            stroke: #405866;
            stroke-width: 4.54055;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="m 61.382024,76.027979 c 0,6.450825 2.525995,11.389345 6.86935,15.219522 5.454445,4.737324 5.25164,8.668295 5.25164,14.111179 0,0.70556 0.303814,1.41151 0.808855,1.91548 0.505041,0.50397 1.111486,0.80517 1.919552,0.80517 h 11.817175 c 0.707058,0 1.414511,-0.3012 1.919552,-0.80517 0.505041,-0.50397 0.808855,-1.10913 0.808855,-1.91548 0,-5.745265 -0.202017,-9.173055 5.555454,-14.41435 4.141343,-3.729383 6.565533,-8.667114 6.565533,-14.916351 C 102.59497,70.383508 100.2706,65.242614 96.634298,61.513231 92.593968,57.481465 87.637018,55.604494 82.729881,55.487237 71.93418,55.229272 61.382024,63.485409 61.382024,76.027979 Z"
          id="bulbBody"
          inkscape:connector-curvature="0"
        />

        <g
          id="g101"
          style="stroke-width: 10; stroke-miterlimit: 4; stroke-dasharray: none"
        >
          <path
            class="st0"
            d="M171.1,51.5v25.3h-14.3V29.6h14.7L188,53.4V29.6h14.3v47.2h-13.7L171.1,51.5z"
            id="path81"
            style="
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
          />
          <path
            class="st0"
            d="M204.4,59.7c0-9.5,7.1-17.9,19.2-17.9c12.2,0,19.2,8.3,19.2,17.9s-7.1,18-19.2,18   C211.5,77.7,204.4,69.2,204.4,59.7z M229.8,59.7c0-3.7-2.2-6.6-6.2-6.6c-3.9,0-6.1,2.9-6.1,6.6c0,3.8,2.2,6.7,6.1,6.7   C227.6,66.4,229.8,63.5,229.8,59.7z"
            id="path83"
            style="
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
          />
          <path
            class="st0"
            d="M244.7,42.6h12.7v3.9c2.1-2.5,6.6-4.7,10.5-4.7V54c-0.6-0.2-1.7-0.4-2.9-0.4c-2.7,0-6.2,0.7-7.6,2.4v20.8   h-12.7V42.6z"
            id="path85"
            style="
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
          />
          <path
            class="st0"
            d="M276.4,66.9V53.7h-5.7v-11h5.7v-9.3h12.7v9.3h6.9v11h-6.9v9.5c0,1.8,1.1,3.2,2.8,3.2c1.1,0,2.3-0.4,2.5-0.6   l2.3,9.6c-1.3,1.2-4.3,2.3-8.6,2.3C280.5,77.7,276.4,74.1,276.4,66.9z"
            id="path87"
            style="
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
          />
          <path
            class="st0"
            d="M322.5,57.8c0-3.5-1.9-4.7-5-4.7c-2.9,0-4.4,1.5-5.4,2.7v21.1h-12.7V29.6h12.7v16.9c2-2.3,5.7-4.7,11.7-4.7   c7.9,0,11.4,4.7,11.4,10.6v24.4h-12.7V57.8z"
            id="path89"
            style="
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
          />
          <path
            class="st0"
            d="M338.4,29.6h25.2c11,0,17,7.4,17,16.2c0,8.8-5.9,15.8-17,15.8h-10.9v15.1h-14.3V29.6z M361.7,41.5h-9.1v8.3   h9.1c2.4,0,4.3-1.6,4.3-4.1C366,43.1,364.1,41.5,361.7,41.5z"
            id="path91"
            style="
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
          />
          <path
            class="st0"
            d="M379.7,59.7c0-9.5,7.1-17.9,19.2-17.9c12.2,0,19.2,8.3,19.2,17.9s-7.1,18-19.2,18   C386.8,77.7,379.7,69.2,379.7,59.7z M405.1,59.7c0-3.7-2.2-6.6-6.2-6.6c-3.9,0-6.1,2.9-6.1,6.6c0,3.8,2.2,6.7,6.1,6.7   C402.9,66.4,405.1,63.5,405.1,59.7z"
            id="path93"
            style="
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
          />
          <path
            class="st0"
            d="M419.3,32c0-4,3.2-7.1,7.1-7.1s7.1,3.2,7.1,7.1c0,4-3.2,7.1-7.1,7.1S419.3,35.9,419.3,32z M420.1,42.6h12.7   v34.2h-12.7V42.6z"
            id="path95"
            style="
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
          />
          <path
            class="st0"
            d="M458.7,57.9c0-3.5-1.9-4.9-5-4.9c-2.9,0-4.4,1.5-5.4,2.7v21.1h-12.7V42.6h12.7v3.9c2-2.3,5.7-4.7,11.7-4.7   c7.9,0,11.4,4.8,11.4,10.8v24.3h-12.7V57.9z"
            id="path97"
            style="
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
          />
          <path
            class="st0"
            d="M479.2,66.9V53.7h-5.7v-11h5.7v-9.3h12.7v9.3h6.9v11h-6.9v9.5c0,1.8,1.1,3.2,2.8,3.2c1.1,0,2.3-0.4,2.5-0.6   l2.3,9.6c-1.3,1.2-4.3,2.3-8.6,2.3C483.4,77.7,479.2,74.1,479.2,66.9z"
            id="path99"
            style="
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
          />
        </g>
        <g
          id="g111"
          style="stroke-width: 10; stroke-miterlimit: 4; stroke-dasharray: none"
        >
          <path
            class="st2"
            d="M156.8,84.8h5.2v36.9h19.3v4.6h-24.4V84.8z"
            id="path103"
            style="
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
          />
          <path
            class="st2"
            d="M205,122.8c-2.5,2.7-5.9,4.2-9.9,4.2c-5.1,0-10.5-3.4-10.5-9.9c0-6.7,5.4-9.9,10.5-9.9c4.1,0,7.5,1.3,9.9,4.1   v-5.4c0-4-3.2-6.3-7.6-6.3c-3.6,0-6.5,1.3-9.2,4.2l-2.2-3.2c3.2-3.4,7.1-5,12-5c6.3,0,11.6,2.9,11.6,10.1v20.6H205V122.8z    M205,114.2c-1.8-2.5-5-3.7-8.3-3.7c-4.4,0-7.4,2.7-7.4,6.6c0,3.8,3,6.5,7.4,6.5c3.3,0,6.5-1.2,8.3-3.7V114.2z"
            id="path105"
            style="
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
          />
          <path
            class="st2"
            d="M215.3,84.8h4.7v16c2.4-3.3,6-5.3,10.1-5.3c7.8,0,13.4,6.2,13.4,15.8c0,9.8-5.5,15.7-13.4,15.7   c-4.2,0-7.9-2.2-10.1-5.2v4.5h-4.7V84.8z M228.9,122.8c6.1,0,9.7-4.9,9.7-11.6c0-6.7-3.6-11.6-9.7-11.6c-3.7,0-7.3,2.2-8.9,4.8   v13.6C221.5,120.7,225.2,122.8,228.9,122.8z"
            id="path107"
            style="
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
          />
          <path
            class="st2"
            d="M247.1,118.9c2,2.3,5.8,4.3,9.9,4.3c4.6,0,7.1-2.1,7.1-5c0-3.3-3.7-4.3-7.7-5.3c-5.1-1.2-10.9-2.5-10.9-8.8   c0-4.7,4-8.6,11.2-8.6c5.1,0,8.7,1.9,11.1,4.4l-2.2,3.2c-1.8-2.2-5.1-3.8-8.9-3.8c-4.1,0-6.7,1.9-6.7,4.5c0,2.9,3.4,3.9,7.3,4.8   c5.2,1.2,11.2,2.7,11.2,9.3c0,5-4,9.1-11.8,9.1c-4.9,0-9.1-1.5-12.1-4.7L247.1,118.9z"
            id="path109"
            style="
              stroke-width: 10;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
            "
          />
        </g>
        <path
          class="st3"
          d="M92.7,89.2c0.3-0.3,0.6-0.5,0.9-0.8c3.6-3.2,5.3-7.2,5.3-12.1c0-4.4-1.7-8.7-4.9-11.8  c-7.7-7.7-19.6-5.7-25.3,1.9c6.2-5,15.8-5.4,22.3,1.1c3.1,3.1,4.9,7.4,4.9,11.8C95.9,83.2,94.9,86.4,92.7,89.2z"
          id="bulbShadow"
          style="stroke-width: 10; stroke-miterlimit: 4; stroke-dasharray: none"
        />
    </svg>
</template>

<script>
import { gsap, MotionPathPlugin } from 'gsap/all'

export default {
  name: 'LabsLogo',
  props: {
    height: {
      type: String,
      default: '40px'
    },
    width: {
      type: String,
      default: '50px'
    }
  },
  mounted () {
    gsap.registerPlugin(MotionPathPlugin)
    gsap.to('#electron2', {
      duration: 2,
      repeat: -1,
      ease: 'none',
      motionPath: {
        path: '#leftRing',
        alignOrigin: [0.5, 0.5],
        offsetX: -118,
        offsetY: -115,
        start: 0.1,
        end: 1.1
      }
    })
    gsap.to('#electron1', {
      duration: 2,
      repeat: -1,
      ease: 'none',
      motionPath: {
        path: '#rightRing',
        alignOrigin: [0.5, 0.5],
        offsetX: -96,
        offsetY: -26,
        start: 0.8,
        end: 1.8
      }
    })
    gsap.to('#electron3', {
      duration: 2,
      repeat: -1,
      ease: 'none',
      motionPath: {
        path: '#middleRing',
        alignOrigin: [0.5, 0.5],
        offsetX: -32,
        offsetY: -91
      }
    })
  }
}
</script>

<style scoped>

.st0 {
  fill: #405866;
}

.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ee8922;
}

.st2 {
  fill: #ee8922;
}

.st3 {
  opacity: 0.25;
  fill-rule: evenodd;
  clip-rule: evenodd;
}

#Layer_1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

</style>
