<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div>
            <LabsLogo id="labsLogo" height="149px" width="500px"/>
            <div v-if="isLoggedIn">
              Logged in! Redirecting...
            </div>
            <div v-else>
              <div class="loginButtons">
                <button id="login-microsoft" class="button" @click="login('microsoft')">
                  Sign In With Microsoft
                  <MicrosoftLogo class="loginIcon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { auth } from '../services/FirebaseService.js'
import * as bulmaToast from '../../node_modules/bulma-toast'
import { mapActions, mapState } from 'vuex'
import MicrosoftLogo from '../components/svg/MicrosoftLogo.vue'
import LabsLogo from '../components/svg/LabsLogo.vue'
import { signInWithPopup, OAuthProvider } from 'firebase/auth'

/**
 * @typedef {import('firebase/auth').UserCredential} UserCredential
 * @typedef {import('firebase/auth').AuthProvider} AuthProvider
 */

export default {
  name: 'login',
  components: { MicrosoftLogo, LabsLogo },
  computed: {
    ...mapState(['user']),
    isLoggedIn () {
      return this.user.loggedIn
    }
  },
  methods: {
    ...mapActions(['setUser']),
    async login () {
      try {
        const msProvider = new OAuthProvider('microsoft.com')
        msProvider.setCustomParameters({
          tenant: '1b851592-2520-4963-88ae-60fa1ed11758'
        })
        await signInWithPopup(auth, msProvider)
      } catch (err) {
        console.error('an error occurred while signing in:', err)
        bulmaToast.toast({
          duration: 5000,
          message: err.toString(),
          opacity: 0.8,
          type: 'is-danger'
        })
      }
    }
  }
}
</script>
<style scoped>
.button {
  margin: 0px 5px 0px 5px;
  height: 45px;
}

.loginIcon {
  margin: 0px 0px 0px 8px;
  height: 25px;
  width: 25px;
}

.loginButtons {
  display: flex;
  justify-content: center;
}
</style>
