<template>
  <div>
    <nav
      class="navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <LabsLightbulb height="35px" width="45px" />
        </a>
        <a
          role="button"
          v-on:click="toggleMenu"
          class="navbar-burger"
          v-bind:class="{ 'is-active': menuOpen }"
          aria-label="menu"
          aria-expanded="false"
          data-target="navMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        class="navbar-menu"
        v-bind:class="{ 'is-active': menuOpen }"
        id="nav-menu"
      >
        <div class="navbar-start">
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link"> Industrial </a>
            <div class="navbar-dropdown is-hidden-touch">
              <a href="https://labs.northpointkc.com/maps" class="dropdown-item">
                Beyond Maps
              </a>
              <a href="https://labs.northpointkc.com/budget-approval" class="dropdown-item is-hidden-touch">
                Budget Approval
              </a>
              <a href="https://sites.google.com/northpointkc.com/npdindustrialdemand" class="dropdown-item is-hidden-touch">
                Industrial Demand
              </a>
            </div>
          </div>
          <div class="navbar-item">
            <a data-testid="beyondchat-link" class="navbar-item" href="https://chat.northpointkc.com/"> Beyond Chat </a>
          </div>
        </div>
        <div class="navbar-end">
          <div class="navbar-item" @click="logout" id="logout">
            <LogoutLogo class="is-hidden-mobile"/>
            <span class="is-hidden-desktop"> Logout </span>
          </div>
        </div>
      </div>
    </nav>
    <div class="body">
      <LabsLogo height="320px" width="400px" />
    </div>

<article id="googleAnalyticsMessage" class="message is-info" :style="'display: ' + googleAnalyticsMessageDisplay">
  <div class="message-header">
    <p>Google Analytics</p>
    <button class="delete" aria-label="delete" @click="handleOptOut(false)"></button>
  </div>
  <div class="message-body">
       The Labs team uses Google Analytics to track website traffic.
      We use this data to understand who uses our applications and how often so we can better customize these tools for your needs.
      You can <a @click="confirmationModalActive=true">opt out</a> of Google Analytics measurements now
      or any time in the future by visiting the Google Analytics section of
      the <a href="https://labs.northpointkc.com/">Labs homepage</a>.
  </div>
   <div class="message-footer">
    <button class="button" @click="handleOptOut(false)">Got it!</button>
  </div>
</article>
<div class="modal" :class="{'is-active': confirmationModalActive}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Confirm</p>
      <button class="delete" aria-label="close" @click="handleOptOut(false)"></button>
    </header>
    <section class="modal-card-body">
      Are you sure you want to opt out of Google Analytics measurements?
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success" @click="handleOptOut(true)">Yes, Opt Out</button>
      <button class="button" @click="handleOptOut(false)">No, Cancel</button>
    </footer>
  </div>
</div>
<div class="modal" :class="{'is-active': errorMessageActive}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head" style="background: #f14668">
      <p class="modal-card-title">Error</p>
      <button class="delete" aria-label="close" @click="errorMessageActive = false"></button>
    </header>
    <section class="modal-card-body">
      Oops, there was an error updating your preferences. Please try again or contact solutions-engineering@northpointkc.com for assistance.
    </section>
    <footer class="modal-card-foot">
      <button class="button" @click="errorMessageActive = false">Ok</button>
    </footer>
  </div>
</div>
    <footer class="footer">
      <div class="content has-text-centered">
        <strong>Questions?</strong><br>
        <p>
          Please send any questions, feedback or requests to
          <strong>solutions-engineering@northpointkc.com</strong>
        </p>
          <p>
          <strong>Google Analytics</strong><br>
          The Labs team uses Google Analytics to track website traffic.
      We use this data to understand who uses our applications and how often so we can better customize these tools for your needs.
      Click <a @click="confirmationModalActive=true">here</a> to opt out of Google Analytics measurements.
        </p>
        <div>
          Icons made by
          <a
            href="https://www.flaticon.com/authors/pixel-perfect"
            title="Pixel perfect"
            >Pixel perfect</a
          >
          from
          <a href="https://www.flaticon.com/" title="Flaticon"
            >www.flaticon.com</a
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { auth, getCurrentUser } from '../services/FirebaseService.js'
import LabsLogo from './../components/svg/LabsLogo.vue'
import LabsLightbulb from './../components/svg/LabsLightbulb.vue'
import LogoutLogo from './../components/svg/LogoutLogo.vue'

export default {
  name: 'Home',
  data () {
    return {
      errorMessageActive: false,
      confirmationModalActive: false,
      kmlDownloadDisabled: false,
      menuOpen: false,
      googleAnalyticsMessageDisplay: 'none'
    }
  },
  components: {
    LabsLogo,
    LabsLightbulb,
    LogoutLogo
  },
  methods: {
    handleOptOut (boolean) {
      this.googleAnalyticsMessageDisplay = 'none'
      this.confirmationModalActive = false
      // set new google analytics opt out custom claim
      fetch(`https://us-central1-northpoint-production.cloudfunctions.net/updateCustomClaims?googleAnalyticsOptOut=${boolean}`, {
        headers: {
          Authorization: 'Bearer ' + this.currentUserToken,
          'Content-Type': 'application/json'
        },
        method: 'POST'
      })
        .then(response => {
          if (!response.ok) {
            response.json().then((res) => {
              throw new Error(`Network response was not ok: ${res}`)
            })
          }
          return response.json()
        })
        .then(data => {
          console.log('Success:', data)
          getCurrentUser().getIdToken(true)
        })
        .catch((err) => {
          console.error(err)
          this.errorMessageActive = true
        })
    },
    async retrieveCustomClaims () {
      // force refresh custom claims
      await getCurrentUser().getIdToken(true)

      // get custom claims
      getCurrentUser().getIdTokenResult()
        .then((idTokenResult) => {
          this.currentUserToken = idTokenResult.token
          // confirm whether user has answered google analytics acceptance prompt
          if (!Object.prototype.hasOwnProperty.call(idTokenResult.claims, 'googleAnalyticsOptOut')) {
            // open google analytics confirmation modal
            this.googleAnalyticsMessageDisplay = 'block'
          } else {
            // Confirm whether user has opted out of google analytics
            if (idTokenResult.claims.googleAnalyticsOptOut) {
              console.log('DISABLING GOOGLE ANALYTICS')
              // if yes, disable ga4/universal analytics for this session
              window['ga-disable-UA-184530775-3'] = true // Universal Analytics
              window['ga-disable-G-K5F56DG3F3'] = true // GA4
            } else {
              // otherwise send data to google analytics
              console.log('SENDING USER DATA')
              // send data to universal google analytics
              window.ga('create', 'UA-184530775-3', 'auto')
              window.ga('set', 'userId', getCurrentUser().uid) // UA basic user id
              window.ga('set', 'dimension1', getCurrentUser().uid) // UA custom user id dimension
              window.ga('send', 'pageview')

              // send data to ga4
              window.gtag('js', new Date())
              window.gtag('config', 'G-K5F56DG3F3')
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    logout () {
      const that = this
      auth.signOut()
        .then(() => {
          that.$router.replace({ name: 'Login' })
        })
        .catch((err) => {
          console.error('an error occurred logging out', err)
        })
    },
    toggleMenu: function () {
      this.menuOpen = !this.menuOpen
    }
  },
  computed: {
    currentUserName: function () {
      return getCurrentUser().email.split('@')[0]
    }
  },
  mounted () {
    // check current users google analytics opt out status
    this.retrieveCustomClaims()

    document.querySelectorAll('.has-dropdown').forEach((i) => {
      i.addEventListener('click', function () {
        i.querySelector('.navbar-dropdown').classList.toggle('is-hidden-touch')
      })
    })
  }
}
</script>
<style scoped>
@media screen and (max-width: 540px) {
  #logout {
    display: flex;
    justify-content: flex-end;
    padding-right: 2.5em;
  }
}

#logout:hover {
  cursor: pointer;
  color: orange;
}

nav {
  background: whitesmoke;
  color: #405866;
}

.navbar-link.disabled {
  opacity: 0.65;
}

.navbar-item.disabled {
  pointer-events: none;
  opacity: 0.65;
  color: #405866;
}

.dropdown-item.disabled {
  pointer-events: none;
  opacity: 0.65;
  color: #405866;
}

.body {
  display: flex;
  align-items: center;
  min-height: 75vh;
}

footer {
  height: 25vh;
}
.nested.dropdown {
  width: 100%;
}

.nested.dropdown:hover > .dropdown-menu {
  display: block;
}
.nested.dropdown .dropdown-menu {
  top: -8px;
  margin-left: 100%;
}
.nested.dropdown .dropdown-trigger button {
  padding: 0px 0px;
  border: 0px;
  font-size: 14px;
  font-weight: 400;
}

.modal-card-body {
  display: block;
  padding: 30px;
  text-align: left;
}

.modal-card-head {
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  text-align: left;
  background-color: #f5f5f5;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
}

.modal-card a {
  color: white;
}

.modal-card-foot {
  height: auto;
}

ol li {
  list-style-position: inside;
  padding-left: 20px;
}

h2 {
  font-size: 1.75em;
  margin-bottom: 0.5714em;
  color: #363636;
  font-weight: 600;
  line-height: 1.125;
}

.navbar-item .navbar-link {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .navbar-dropdown a {
    width: 100%;
    text-align: right;
    padding: 5px 25px;
    align-self: flex-end;
  }

  .navbar-dropdown:last-child:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    height: 1px;
    width: 70%;
    border-bottom: 1px solid orange;
  }
}
</style>
