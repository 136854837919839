<template>
  <svg
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 140 140"
            style="enable-background: new 0 0 519.1 154.7"
            xml:space="preserve"
            sodipodi:docname="NorthPointLabs-Logo (1) (2).svg"
            inkscape:version="1.0beta2 (2b71d25, 2019-12-03)"
            :height="height"
            :width="width"
          >
            <metadata id="metadata120">
              <rdf:RDF>
                <cc:Work rdf:about="">
                  <dc:format>image/svg+xml</dc:format>
                  <dc:type
                    rdf:resource="http://purl.org/dc/dcmitype/StillImage"
                  />
                </cc:Work>
              </rdf:RDF>
            </metadata>
            <defs id="defs118" />
            <sodipodi:namedview
              pagecolor="#ffffff"
              bordercolor="#666666"
              inkscape:document-rotation="0"
              borderopacity="1"
              objecttolerance="10"
              gridtolerance="10"
              guidetolerance="10"
              inkscape:pageopacity="0"
              inkscape:pageshadow="2"
              inkscape:window-width="2170"
              inkscape:window-height="1501"
              id="namedview116"
              showgrid="false"
              inkscape:zoom="7.76"
              inkscape:cx="98.38316"
              inkscape:cy="53.806441"
              inkscape:window-x="621"
              inkscape:window-y="2017"
              inkscape:window-maximized="0"
              inkscape:current-layer="Layer_1"
            />
            <path
              d="M 144.52927,76.382118 A 62.389267,22.645834 0 0 1 82.140007,99.027952 62.389267,22.645834 0 0 1 19.75074,76.382118 62.389267,22.645834 0 0 1 82.140007,53.736284 62.389267,22.645834 0 0 1 144.52927,76.382118"
              style="
                fill: none;
                fill-opacity: 1;
                stroke: #405866;
                stroke-width: 4.76591;
                stroke-miterlimit: 4;
                stroke-dasharray: none;
                stroke-opacity: 1;
              "
              id="middleRing"
            />
            <path
              inkscape:connector-curvature="0"
              d="M 113.27314,130.44827 A 62.389267,22.645834 60.065186 0 1 62.515263,87.682715 62.389267,22.645834 60.065186 0 1 51.006857,22.31597 a 62.389267,22.645834 60.065186 0 1 50.757883,42.765551 62.389267,22.645834 60.065186 0 1 11.5084,65.366749"
              style="
                fill: none;
                fill-opacity: 1;
                stroke: #405866;
                stroke-width: 4.76591;
                stroke-miterlimit: 4;
                stroke-dasharray: none;
                stroke-opacity: 1;
              "
              id="leftRing"
            />
            <path
              inkscape:connector-curvature="0"
              d="M 113.3171,22.341306 A 22.645834,62.389267 29.981407 0 1 101.75554,87.698667 22.645834,62.389267 29.981407 0 1 50.962907,130.42293 22.645834,62.389267 29.981407 0 1 62.524463,65.065562 22.645834,62.389267 29.981407 0 1 113.3171,22.341306"
              style="
                fill: none;
                fill-opacity: 1;
                stroke: #405866;
                stroke-width: 4.76591;
                stroke-miterlimit: 4;
                stroke-dasharray: none;
                stroke-opacity: 1;
              "
              id="rightRing"
            />

            <g id="electron1">
              <path
                style="
                  fill: #ffffff;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 10;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                "
                id="path79-0-3"
                d="m 102.34204,29.544819 c -1.91264,3.415422 -6.284362,4.644974 -9.699789,2.595721 -3.415422,-1.912637 -4.644974,-6.284377 -2.595721,-9.699799 1.912637,-3.415422 6.284378,-4.644974 9.699794,-2.595721 3.278806,1.912637 4.508356,6.284376 2.595716,9.699799"
                inkscape:connector-curvature="0"
              />
              <path
                inkscape:connector-curvature="0"
                style="
                  fill: #ee8922;
                  fill-opacity: 1;
                  stroke-width: 10;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                "
                id="path126"
                d="m 91.649217,28.570559 c -1.4,-2.5 -0.6,-5.7 1.9,-7.1 2.5,-1.4 5.699997,-0.6 7.099993,1.9 1.4,2.5 0.6,5.7 -1.899993,7.1 -2.5,1.5 -5.7,0.6 -7.1,-1.9 z"
              />
            </g>
            <g id="electron3">
              <path
                inkscape:connector-curvature="0"
                d="m 38.214894,93.530927 c -1.912637,3.415422 -6.284376,4.644974 -9.699799,2.595721 -3.415422,-1.912637 -4.644974,-6.284377 -2.595721,-9.699799 1.912637,-3.415422 6.284377,-4.644974 9.699799,-2.595721 3.278805,1.912637 4.508357,6.284376 2.595721,9.699799"
                id="path79-0"
                style="
                  fill: #ffffff;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 10;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                "
              />
              <path
                inkscape:connector-curvature="0"
                style="
                  fill: #ee8922;
                  fill-opacity: 1;
                  stroke-width: 10;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                "
                id="path79"
                d="m 36.555054,92.578888 c -1.4,2.5 -4.6,3.4 -7.1,1.9 -2.5,-1.4 -3.4,-4.6 -1.9,-7.1 1.4,-2.5 4.6,-3.4 7.1,-1.9 2.4,1.4 3.3,4.6 1.9,7.1"
              />
            </g>
            <g id="electron2">
              <path
                style="
                  fill: #ffffff;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 10;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                "
                id="path79-0-4"
                d="m 124.96539,118.47382 c -1.91263,3.41542 -6.28437,4.64497 -9.6998,2.59572 -3.41542,-1.91264 -4.64497,-6.28438 -2.59572,-9.6998 1.91264,-3.41542 6.28438,-4.64497 9.6998,-2.59572 3.27881,1.91264 4.50836,6.28438 2.59572,9.6998"
                inkscape:connector-curvature="0"
              />
              <path
                inkscape:connector-curvature="0"
                style="
                  fill: #ee8922;
                  fill-opacity: 1;
                  stroke-width: 10;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                "
                id="path124"
                d="m 118.77407,109.72178 c 2.9,0 5.2,2.3 5.2,5.2 0,2.9 -2.3,5.2 -5.2,5.2 -2.9,0 -5.2,-2.3 -5.2,-5.2 -0.1,-2.9 2.3,-5.2 5.2,-5.2 z"
              />
            </g>
            <path
              inkscape:connector-curvature="0"
              d="m 94.210729,122.18869 c -3.780383,6.71254 -12.421249,9.12904 -19.171931,5.10153 -6.750683,-3.75905 -9.180926,-12.35108 -5.13052,-19.06362 3.780385,-6.71251 12.421254,-9.129054 19.171933,-5.10153 6.480653,3.75901 8.910898,12.35106 5.130518,19.06362"
              id="coverlet"
              style="
                fill: #ffffff;
                fill-opacity: 1;
                stroke: none;
                stroke-width: 12.8337;
                stroke-miterlimit: 4;
                stroke-dasharray: none;
              "
            />
            <path
              style="
                stroke-width: 10;
                stroke-miterlimit: 4;
                stroke-dasharray: none;
                fill: #405866;
                fill-opacity: 1;
              "
              d="m 88.900391,121.19922 c 3.2,0 3.2,4.90039 0,4.90039 h -3.300782 c -0.2,1.3 -1.7,2.30078 -3.5,2.30078 -1.8,0 -3.3,-1.00078 -3.5,-2.30078 h -3.40039 c -3.2,0 -3.2,-4.90039 0,-4.90039 z"
              id="bulbBaseLower"
            />
            <path
              style="
                stroke-width: 10;
                stroke-miterlimit: 4;
                stroke-dasharray: none;
                stroke: none;
                stroke-opacity: 1;
                fill: #405866;
                fill-opacity: 1;
              "
              d="m 91.099609,114.69922 c 3.2,0 3.2,4.90039 0,4.90039 h -18 c -3.2,-0.1 -3.2,-4.90039 0,-4.90039 z"
              id="bulbBaseUpper"
            />
            <path
              style="
                fill: #ffffff;
                stroke: #405866;
                stroke-width: 4.54055;
                stroke-miterlimit: 4;
                stroke-dasharray: none;
                stroke-opacity: 1;
              "
              d="m 61.382024,76.027979 c 0,6.450825 2.525995,11.389345 6.86935,15.219522 5.454445,4.737324 5.25164,8.668295 5.25164,14.111179 0,0.70556 0.303814,1.41151 0.808855,1.91548 0.505041,0.50397 1.111486,0.80517 1.919552,0.80517 h 11.817175 c 0.707058,0 1.414511,-0.3012 1.919552,-0.80517 0.505041,-0.50397 0.808855,-1.10913 0.808855,-1.91548 0,-5.745265 -0.202017,-9.173055 5.555454,-14.41435 4.141343,-3.729383 6.565533,-8.667114 6.565533,-14.916351 C 102.59497,70.383508 100.2706,65.242614 96.634298,61.513231 92.593968,57.481465 87.637018,55.604494 82.729881,55.487237 71.93418,55.229272 61.382024,63.485409 61.382024,76.027979 Z"
              id="bulbBody"
              inkscape:connector-curvature="0"
            />
  </svg>
</template>

<script>
import { gsap, MotionPathPlugin } from 'gsap/all'

export default {
  name: 'LabsLightBulb',
  props: {
    height: {
      type: String,
      default: '40px'
    },
    width: {
      type: String,
      default: '50px'
    }
  },
  mounted () {
    gsap.registerPlugin(MotionPathPlugin)
    gsap.to('#electron2', {
      duration: 2,
      repeat: -1,
      ease: 'none',
      motionPath: {
        path: '#leftRing',
        alignOrigin: [0.5, 0.5],
        offsetX: -118,
        offsetY: -115,
        start: 0.1,
        end: 1.1
      }
    })
    gsap.to('#electron1', {
      duration: 2,
      repeat: -1,
      ease: 'none',
      motionPath: {
        path: '#rightRing',
        alignOrigin: [0.5, 0.5],
        offsetX: -96,
        offsetY: -26,
        start: 0.8,
        end: 1.8
      }
    })
    gsap.to('#electron3', {
      duration: 2,
      repeat: -1,
      ease: 'none',
      motionPath: {
        path: '#middleRing',
        alignOrigin: [0.5, 0.5],
        offsetX: -32,
        offsetY: -91
      }
    })
  }
}
</script>

<style scoped>

.st0 {
  fill: #405866;
}

.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ee8922;
}

.st2 {
  fill: #ee8922;
}

.st3 {
  opacity: 0.25;
  fill-rule: evenodd;
  clip-rule: evenodd;
}

#Layer_1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

</style>
