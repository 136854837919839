import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {
      loggedIn: false,
      data: null
    }
  },
  mutations: {
    SET_LOGGED_IN (state, value) {
      state.user.loggedIn = value
    },
    SET_USER (state, data) {
      state.user.data = data
    }
  },
  actions: {
    setUser ({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null)
      if (user) {
        if (user.claims.npEmployee === true) {
          commit('SET_USER', {
            displayName: user.displayName,
            email: user.email
          })
        } else {
          commit('SET_USER', null)
          commit('SET_LOGGED_IN', false)
        }
      } else {
        commit('SET_USER', null)
      }
    }
  },
  modules: {
  }
})
