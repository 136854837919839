import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import store from '../store/index.js'
import { getAuth, signOut } from 'firebase/auth'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: (route) => ({ query: route.query.r }) // redirected from
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Authentication and Authorization guard
router.beforeEach((to, from, next) => {
  // handle un-authenticated users
  if (!store.state.user.loggedIn) {
    // allow them to go to the login route with no redirect. a redirect would trigger an infinite redirect loop
    if (to.fullPath === '/login') {
      next()
      return
    }
    // otherwise, send them to login
    console.log('not logged in')
    next({ path: '/login' })
    return
  }

  // checks if a user is set in store, a user's custom claims are set before being committed to store
  if (!store.state.user?.data) {
    signOut(getAuth()).catch((err) => {
      console.error('error logging user out', err)
    })
    next({ path: '/login' })
    return
  }

  // if they are trying to get to the login route while signed in, send them to home
  if (to.fullPath === '/login') {
    next({ name: 'Home' })
    return
  }

  // If everything else is OK, let them through
  next()
})
export default router
