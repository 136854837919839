<template>
  <svg
    id="light"
    enable-background="new 0 0 24 24"
    height="28"
    viewBox="0 0 24 24"
    width="30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m13.5 21h-4c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h4c.827 0 1.5-.673 1.5-1.5v-5c0-.276.224-.5.5-.5s.5.224.5.5v5c0 1.378-1.121 2.5-2.5 2.5z"
        style="stroke: #405866; stroke-width: 0.5"
      />
    </g>
    <g>
      <path
        d="m23.5 11h-10c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h10c.276 0 .5.224.5.5s-.224.5-.5.5z"
        style="stroke: #405866; stroke-width: 0.5"
      />
    </g>
    <g>
      <path
        d="m8 24c-.22 0-.435-.037-.638-.109l-5.99-1.997c-.82-.273-1.372-1.035-1.372-1.894v-18c0-1.103.897-2 2-2 .222 0 .438.037.639.11l5.989 1.996c.82.272 1.372 1.034 1.372 1.894v18c0 1.103-.897 2-2 2zm-6-23c-.552 0-1 .449-1 1v18c0 .428.276.808.688.946l6 2c.656.233 1.312-.292 1.312-.946v-18c0-.429-.276-.809-.688-.945l-6-2c-.103-.037-.208-.055-.312-.055z"
        style="stroke: #405866; stroke-width: 0.5"
      />
    </g>
    <g>
      <path
        d="m15.5 8c-.276 0-.5-.224-.5-.5v-5c0-.827-.673-1.5-1.5-1.5h-11.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11.5c1.379 0 2.5 1.122 2.5 2.5v5c0 .276-.224.5-.5.5z"
        style="stroke: #405866; stroke-width: 0.5"
      />
    </g>
    <g>
      <path
        d="m19.5 15c-.128 0-.256-.049-.354-.146-.195-.195-.195-.512 0-.707l3.646-3.646-3.646-3.646c-.195-.195-.195-.512 0-.707s.512-.195.707 0l4 4c.195.195.195.512 0 .707l-4 4c-.097.096-.225.145-.353.145z"
        style="stroke: #405866; stroke-width: 0.5"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LogoutLogo'
}
</script>
