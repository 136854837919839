import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { initializeFirebase, auth } from './services/FirebaseService'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faAngleRight)

const executeRedirect = (destination) => {
  console.log(`requested redirect to ${destination}`)
  if (!destination) {
    router.replace({ name: 'Home' })
    return
  }
  // detect if this is a full url starting with http or https and set the default domain if it is only a path
  if (!destination.match(/^https?:\/\//)) {
    destination = new URL(destination, 'https://labs.northpointkc.com')
  }
  console.log(`redirecting to ${destination.toString()}`)
  location.replace(destination)
}

const afterLogin = async (user, userToken) => {
  await store.dispatch('setUser', { ...user, ...userToken })
  const redirectTo = router.currentRoute.value.redirectedFrom?.query?.r
  // only redirect if there is a redirect query param or if the user is on the login page
  if (redirectTo || router.currentRoute.value.name === 'Login') {
    executeRedirect(redirectTo)
  }
}

const authorizeUser = async (userToken) => {
  const response = await fetch('https://us-central1-northpoint-production.cloudfunctions.net/updateCustomClaims', {
    headers: {
      Authorization: 'Bearer ' + userToken?.token,
      'Content-Type': 'application/json'
    },
    method: 'POST'
  })
  // force refresh the token to get the updated claims, regardless of whether the backend call succeeded. A failed backend call may still have updated the claims
  userToken = await auth.currentUser.getIdTokenResult(true)
  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('This does not appear to be a NorthPoint Google Account - please sign in again with your NorthPoint Email')
    }
    if (response.status === 500) {
      throw new Error('Failed to authorize user')
    }
  }
  return userToken
}

initializeFirebase()
auth.onAuthStateChanged(async user => {
  let userToken = await user?.getIdTokenResult()

  // 1. if there is no user, set user to null in the store and redirect to login
  if (!user || !userToken) {
    store.dispatch('setUser', null)
    if (router.currentRoute.value.name !== 'Login') {
      router.replace({ name: 'Login' })
    }
    return
  }

  // 2. if there is a user and they are an employee, set user in the store and redirect based on query param
  if (userToken?.claims?.npEmployee) {
    await afterLogin(user, userToken)
    return
  }

  // 3. if there is a user and they are not an employee, call the backend to see if they can be authorized
  userToken = await authorizeUser(userToken)
  //    a. if they can be authorized, set user in the store and redirect based on query param
  if (userToken?.claims?.npEmployee) {
    await afterLogin(user, userToken)
    return
  }
  //    b. if they cannot be authorized, set user to null in the store, sign out, and redirect to login
  store.dispatch('setUser', null)
  auth.signOut()
  router.replace({ name: 'Login' })
})

createApp(App).use(store).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
