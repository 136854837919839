import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// The config variable is created using information from firebase.
const config = {
  apiKey: 'AIzaSyCTayMAzOhyROj9VuFlt9Jm7RpSGPFwwYE',
  authDomain: 'northpoint-production.firebaseapp.com',
  databaseURL: 'https://northpoint-production.firebaseio.com',
  projectId: 'northpoint-production',
  storageBucket: 'northpoint-production.appspot.com',
  messagingSenderId: '986447360139',
  appId: '1:986447360139:web:b9bad40bd24eede68f248e'
}

/** @typedef {import('firebase/auth').Auth} Auth */

/** @type {Auth} */
let auth

const initializeFirebase = () => {
  const app = initializeApp(config)
  auth = getAuth(app)
  return app
}

const getCurrentUser = () => auth.currentUser

export { initializeFirebase, getCurrentUser, auth }
